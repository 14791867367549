<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="wrapp-section-title section-title-center">
            <div class="section-subtitle">Our Clients</div>
            <h2 class="section-title">{{title}}</h2>
            <p
              
            >By way of examples of our work, the companies below represent a wide range of industries, and we are working with them to provide either cloud services or full end to end software development solutions.</p>
          </div>
          <projects />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- Begin our customers -->
          <!-- <section class="section">
            <div class="container">
              <div class="row items">
                <div class="col-12">
                  <div class="wrapp-section-title section-title-center">
                    <div class="section-subtitle">The best</div>
                    <h2 class="section-title">Our customers</h2>
                    <p
                      class="section-desc"
                    >Our customers have disrupted industries, opened new markets, and made countless lives better. We are privileged to work with hundreds of future-thinking businesses, including many of the world’s top hardware, software, and consumer brands.</p>
                  </div>
                </div>
              </div>

              <projects />
              
            </div>
          </section>-->
          <!-- End our customers -->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Work",
  data() {
    return {
      title: "Work"
    };
  }
};
</script>

<style>
</style>